import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'impressum',
    loadChildren: () => import('./imprint/imprint.module').then(m => m.ImprintModule)
  },
  {
    path: 'datenschutz',
    loadChildren: () => import('./privacy/privacy.module').then(m => m.PrivacyModule)
  },
  {
    path: 'christoph-hauke',
    loadChildren: () => import('./christoph-hauke/christoph-hauke.module').then(m => m.ChristophHaukeModule)
  },
  {
    path: 'geschaeftsstrategie',
    loadChildren: () => import('./business-strategy/business-strategy.module').then(m => m.BusinessStrategyModule)
  },
  {
    path: 'employer-branding',
    loadChildren: () => import('./employer-branding/employer-branding.module').then(m => m.EmployerBrandingModule)
  },
  {
    path: 'seite-nicht-gefunden',
    loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)
  },
  {
    path: 'kontakt',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'fuehrung',
    loadChildren: () => import('./leadership/leadership.module').then(m => m.LeadershipModule)
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/seite-nicht-gefunden'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
