import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { init as initSentry } from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

initSentry({
  dsn: 'https://6d6e98870917460ba0306d089cc90d8c@o245955.ingest.sentry.io/5684603',
  environment: location.hostname
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
