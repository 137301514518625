import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hauk-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  mapsLinkGoogle = 'https://goo.gl/maps/fdGYoe3khiMNgu2j6';
  mapsLinkApple = 'https://maps.apple.com/?address=Prinz-Georg-Straße 91, 40479 Düsseldorf, Deutschland';
  mapsLink = this.iOSDevice() ? this.mapsLinkApple : this.mapsLinkGoogle;

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * https://stackoverflow.com/a/9039885
   */
  private iOSDevice() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  }

}
