<footer class="text-blue">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-3 col-xxl-4 mb-3">
        <a routerLink="/">
          <img src="/assets/christoph-hauke-logo-128px.png" height="64px" alt="" class="mb-3">
        </a>
        <div class="d-md-none d-lg-block">
          <ng-container *ngTemplateOutlet="address"></ng-container>
        </div>
      </div>

      <div class="d-none d-md-flex flex-column col-md-6 d-lg-none mb-3">
        <ng-container *ngTemplateOutlet="address"></ng-container>
      </div>

      <div class="d-none d-md-flex col-md-12 d-lg-none"></div>

      <div class="col-12 col-md-6 col-lg-3 col-xxl-2 mb-3">
        <h1>Kontakt</h1>
        <a href="tel:+492114497227">
          <span class="d-lg-none d-xl-inline">Tel.:</span>
          +49 211 449 7227
        </a>
        <a href="mailto:anfrage@christophhauke.de">
          <span class="d-lg-none d-xl-inline d-xxl-none">Mail:</span>
          anfrage@christophhauke.de
        </a>
      </div>

      <div class="col-12 col-md-6 col-lg-2 mb-3">
        <h1>Leistungen</h1>
        <a routerLink="/christoph-hauke">
          Christoph Hauke
        </a>
        <a routerLink="/geschaeftsstrategie">
          Geschäftsstrategie
        </a>
        <a routerLink="/fuehrung">
          Führung
        </a>
        <a routerLink="/employer-branding">
          Employer Branding
        </a>
      </div>

      <div class="col-12 col-md-6 col-lg-2 mb-3">
        <h1>Rechtliches</h1>
        <a routerLink="/impressum">
          Impressum
        </a>
        <a routerLink="/datenschutz">
          Datenschutz
        </a>
      </div>

      <div class="col-12 col-md-6 col-lg-2">
        <h1>Folge mir</h1>
        <a href="https://www.xing.com/profile/Christoph_Hauke">
          XING
        </a>
        <a href="http://linkedin.com/in/christophhauke/">
          LinkedIn
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <hr>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <a class="exportarts" href="https://www.exportarts.io" target="_blank" rel="noopener nofollow">
          Made with
          <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18">
              <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
          </svg>
          by Exportarts
        </a>
      </div>
    </div>
  </div>
</footer>

<ng-template #address>
  <address class="m-0">
    Christoph Hauke - 3Gen Worx<br>
    Prinz-Georg-Straße 91<br>
    40479 Düsseldorf
  </address>
  <a [href]="mapsLink" target="_blank" rel="noopener nofollow">
    <small>
      Auf Karte anzeigen
      <svg class="maps-link" xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" width="16">
        <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/>
      </svg>
    </small>
  </a>
</ng-template>
