import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { createErrorHandler } from '@sentry/angular';
import { NgxCookieBannerModule } from 'ngx-cookie-banner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationModule } from './navigation/navigation.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ScullyLibModule,
    NavigationModule,
    HttpClientModule,
    NgxCookieBannerModule.forRoot({
      cookieName: 'christoph-hauke-cookie-consent',
    })
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        logErrors: true
      }),
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
