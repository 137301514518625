<hauk-navbar #navbar></hauk-navbar>
<div class="wrapper">
  <router-outlet></router-outlet>
</div>
<hauk-footer></hauk-footer>

<ngx-cookie-banner #cookie>
  <div class="banner-inner">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-between">
          Unsere Webseite verwendet Cookies
          <a role="button" (click)="cookie.dismiss()">OK</a>
        </div>
      </div>
    </div>
  </div>
</ngx-cookie-banner>
