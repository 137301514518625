<nav class="py-3 fixed-top large" #nav>
  <div class="container">
    <div class="row">
      <div class="col d-flex align-items-center justify-content-between main-nav">
        <a routerLink="/" class="logo">
          <img src="/assets/christoph-hauke-logo-128px.png" alt="">
        </a>
        <a routerLink="/christoph-hauke" class="d-none d-md-inline">
          Christoph Hauke
        </a>
        <a routerLink="/geschaeftsstrategie" class="d-none d-md-inline">
          Geschäftsstrategie
        </a>
        <a routerLink="/fuehrung" class="d-none d-lg-inline">
          Führung
        </a>
        <a routerLink="/employer-branding" class="d-none d-lg-inline">
          Employer Branding
        </a>
        <a href="https://blog.christophhauke.de" class="d-none d-xl-inline">
          Blog
        </a>
        <div class="d-none d-lg-inline">
          <hauk-button
            text="Kontakt"
            routerLink="/kontakt"
          ></hauk-button>
        </div>
        <div class="d-lg-none">
          <span role="button" (click)="toggleDetailNav()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/></svg>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="container d-flex align-items-center detail-nav d-none">
    <div class="row w-100">
      <div class="col d-flex flex-column w-100 text-center">
        <a *ngFor="let link of detailNavLinks" [routerLink]="link.routerLink" (click)="toggleDetailNav()">
          {{link.label}}
        </a>
        <a href="https://blog.christophhauke.de">
          Blog
        </a>
      </div>
    </div>
  </div>
</nav>
