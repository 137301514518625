import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';

interface NavLink {
  routerLink: string;
  label: string;
}

@Component({
  selector: 'hauk-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @ViewChild('nav')
  private nav?: ElementRef<HTMLDivElement>;

  readonly detailNavLinks: NavLink[] = [
    {
      routerLink: '/christoph-hauke',
      label: 'Christoph Hauke'
    },
    {
      routerLink: '/geschaeftsstrategie',
      label: 'Geschäftsstrategie'
    },
    {
      routerLink: '/fuehrung',
      label: 'Führung'
    },
    {
      routerLink: '/employer-branding',
      label: 'Employer Branding'
    },
    {
      routerLink: '/kontakt',
      label: 'Kontakt'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('window:scroll')
  private onWindowScroll() {
    const threshold = 50;
    const className = 'large';

    if (this.nav?.nativeElement) {
      const offset = window.scrollY;
      if (offset <= threshold && !this.nav.nativeElement.classList.contains(className)) {
        this.nav.nativeElement.classList.add(className);
      }
      if (offset > threshold && this.nav.nativeElement.classList.contains(className)) {
        this.nav.nativeElement.classList.remove(className);
      }
    }
  }

  toggleDetailNav() {
    document.getElementsByTagName('body').item(0)?.classList.toggle('no-scroll');
    document.getElementsByClassName('detail-nav').item(0)?.classList.toggle('d-none');
    document.getElementsByClassName('main-nav').item(0)?.classList.toggle('hide');
  }

}
