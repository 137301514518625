import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'hauk-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input()
  text!: string;

  @Input()
  href?: string;

  @Input()
  routerLink?: string;

  @Input()
  mode: 'primary' | 'secondary' = 'primary';

  @Input('class')
  buttonClass?: string;

  @Input()
  disabled = false;

  @Output()
  onClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
