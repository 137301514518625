<ng-container *ngIf="href">
  <a
    [href]="href"
    target="_blank"
    rel="noopener nofollow"
    [ngClass]="mode + ' ' + buttonClass"
  >{{text}}</a>
</ng-container>

<ng-container *ngIf="routerLink">
  <a
    [routerLink]="routerLink"
    [ngClass]="mode + ' ' + buttonClass"
  >{{text}}</a>
</ng-container>

<ng-container *ngIf="!href && !routerLink">
  <button
    type="button"
    [disabled]="disabled"
    [ngClass]="mode + ' ' + buttonClass"
    (click)="onClick.emit()"
  >{{text}}</button>
</ng-container>
